import * as Config from './Config';

const mode= "fetch";
const dataMode= "json";

function doPost(url: string, data1: any){
    let data: string | FormData;
    //debugger;
    if (dataMode ==="json"){
        data=JSON.stringify(data1)
    }else{
        //convertir por medio de foreach o keys cada clave segun su tipo de dato.
        data = new FormData();
        throw new Error("DoPost: not implemented");
    }
    if (mode === "fetch"){
        return new Promise((resolve,reject) => {
            console.log("Sending data:", data1);
            console.log('Url:',Config.default.BasePath + url);
            fetch(Config.default.BasePath + url, {
                    method: 'POST',
                    body: data,
                    headers : {
                        //'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        //'Content-Type': 'application/x-www-form-urlencoded'
                    },
            })
            .then(data => resolve(data.json()))
            .catch(err => reject(err));
        });
    }else{
        throw new Error("DoPost: Mode not set");
    }
}

class ApiService{
    
    public async editarProducto(token: String,idProducto: number, titulo: string, descripcion: string,
        precio: number, datosContacto: string, imagenes: string){
            return doPost("productos/editar",{
                idProducto: idProducto,
                token: token,
                titulo: titulo,
                descripcion: descripcion,
                precio: precio,
                datosContacto: datosContacto,
                images: imagenes    
            });
    }

    public async reactivateProducto(token: String, idProducto: number){
        return doPost("productos/reactivate",{
            token: token,
            idProducto: idProducto
        });
    }

    public async getOneProductoMio(token: String, idProducto: number){
        return doPost("productos/getOneMine",{
            token: token,
            idProducto: idProducto
        });
    }
    
    public async pauseProducto(token: String, idProducto: number){
        return doPost("productos/pause",{
            token: token,
            idProducto: idProducto
        });
    }

    public async getProductosUsuariosPanel(token: String){
        return doPost("productos/getPanelFromUser",{
            token: token,
        });
    }
    
    public async setAvatar(tok: string | null, avatar2: string) {
        return doPost('users/setAvatar',{
            token: tok,        
            imagen: avatar2
        });
    }

    public async uploadImage(b64Data: any) {
        //saco la primera parte.
        const spl = b64Data.split('base64,');
        return new Promise((resolve,reject) => {
            const data = new FormData();
            data.append('data',spl[1].toString());

            fetch(Config.default.UploadBase64Path, {
                method: 'POST',
                body: data
            })
            .then(data => resolve(data.json()))
            .catch(err => reject(err));
        });
    }

    public async publicar(token: String,titulo: string, descripcion: string,
        precio: number, datosContacto: string, imagenes: string){
            return doPost("productos/publicar",{
                token: token,
                titulo: titulo,
                descripcion: descripcion,
                precio: precio,
                datosContacto: datosContacto,
                images: imagenes,                
            });
    }
    
    public async saveApodo(tok: string, apodo: string) {
        return doPost('users/setApodo',{
            token: tok,        
            apodo: apodo,
        });
    }

    public async getUserSettings(tok: string) {
        return doPost('users/getSettings',{
            token: tok,        
        });
    }

    
    public async getOneUser(idUsuario: number) {
        return doPost('users/getOne',{
            idUsuario: idUsuario        
        });    
    }

    public async getPerfil(idPerfil: number) {
        return doPost('productos/getActiveFromUser',{
            idUsuario: idPerfil            
        });    
    }

    public async getDatosContacto(token: string, idProd: number) {
        return doPost('users/getDatosContacto',{
            token: token,
            idProducto: idProd,
        });    
    }

    public async doAnonLogin(token: String){
        return doPost('users/loginAnon',{
            token: token
        });
    }

    public async getSaves(token: String){
        return doPost('saves/list',{
            token: token
        });
    }
    
    public async getMainPage(page: number){
        return doPost('mainpage/get/' + page,{
        });
    }

    public async getOneProducto(token: string, idProd: number){
        // return doPost('productos/getOne',{
        //     token: token,
        //     idProducto: idProd,
        // });
        //return new Promise(async (resolve,reject) => {
            let bodyData = {
                token: token,
                idProducto: idProd,
            }
            return fetch(Config.default.BasePath + 'productos/getOne', {
                method: 'POST',
                body: JSON.stringify(bodyData),
                headers : {
                    //'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    //'Content-Type': 'application/x-www-form-urlencoded'
                },
            })
            .then(data => (data.json()))
            .catch(err => (err));
        //}
    }

}

export default ApiService;