import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import NoPage from "./Pages/NoPage";
import Producto from "./Pages/Producto";
import SearchResults from "./Pages/SearchResults";
import SavesList from "./Pages/Saves";
import Perfil from "./Pages/Perfil";
import Settings from "./Pages/Settings";
import Publicar from "./Pages/Publicar";
import PanelUsuario from "./Pages/PanelUsuario";
import AyudaPage from "./Pages/AyudaPage";
import Editar from "./Pages/Editar";

const RouterWeb = (props:any) => {
    return (
        <BrowserRouter>
            {props?.children}
            <Routes>
                <Route path="/" element={
                    <Navigate to="/ndv" ></Navigate>
                } />

                <Route path="/ndv">
                    <Route index element={<HomePage showModal={props.showModal}/>} />
                    
                    {/* <Route path="perfil/:idUser" element={<Perfil />} /> */}
                    <Route path="ayuda" element={<AyudaPage />} />
                    <Route path="search/:query" element={<SearchResults showModal={props.showModal}/>} />
                    <Route path="producto/:idProd" element={<Producto showModal={props.showModal}/>} />
                    <Route path="perfil/:idPerfil/:apodo" element={<Perfil showModal={props.showModal}/>} />
                    <Route path="saves" element={<SavesList showModal={props.showModal}/>} />
                    <Route path="publicar" element={<Publicar showModal={props.showModal}  />} />
                    <Route path="editar/:idProd" element={<Editar showModal={props.showModal}  />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="panel" element={<PanelUsuario showModal={props.showModal}/>} />
                    
                    {/* opcional el chat por ahora */}
                    {/* <Route path="chat/:idChat" element={<PaginaEnConstruccion />} /> */}
                    {/* creo que las notificaciones no aplica mas */}
                    {/* <Route path="notificaciones" element={<PaginaEnConstruccion />} /> */}
                    
                    <Route path="*" element={<NoPage />} />
                </Route>
                <Route path="*" element={
                    <Navigate to="/ndv" ></Navigate>
                } />
            </Routes>
        </BrowserRouter>
    )
}

export default RouterWeb;
