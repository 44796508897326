import React, { useEffect, useState } from "react";
import CenteredMessage from "../components/CenteredMessage";
import { useParams } from 'react-router-dom'
import ListadoProductos from "../components/ListadoProductos";
import ApiService from "../ApiService";
import Avatar from "../components/Avatar";
import HiStatsCounter from "../stats/HiStatsCounter";

const Perfil = (props: any) => {
    const {idPerfil, apodo} = useParams();
    console.log('idPerfil:', idPerfil);
    console.log('apodo:', apodo);
    const [productos,setProductos] = useState([]);
    const [userData,setUserData]:any = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let idPerfilNumero = parseInt(idPerfil || '0');
        (new ApiService()).getPerfil(idPerfilNumero).then((data1: any) => {
            console.log(data1);
            setProductos(data1.data?.productos);
            fetchUserData(idPerfilNumero);

        }).catch(err => {
            console.log(err);
            setLoading(false);
        })
    },[]);

    const fetchUserData = (idUsuario: number) => {
        (new ApiService()).getOneUser(parseInt(idPerfil || '0')).then((data1: any) => {
            console.log(data1);
            setUserData(data1.data?.usuario);
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        })  
    }

    if ( loading === true){
        return (<CenteredMessage>Cargando...</CenteredMessage>)
    }

    if ( userData === null){
        return (<CenteredMessage>Perfil No Valido</CenteredMessage>)
    }

    return (<div>
                <div className="flexColumn">
                    <h2>Perfil de {userData?.apodo}</h2>
                    <Avatar pathImagen={userData?.pathRel} />
                </div>
                {(productos.length === 0)?
                    <h3>El usuario no publico nada todavia.</h3>
                :null}
                <ListadoProductos productos={productos} />
                <HiStatsCounter />
            </div>    
    )
}

export default Perfil;