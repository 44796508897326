import React, { useEffect, useState } from "react";

import ProductoDatos from "../components/ProductoDatos";
import Separator from "../components/Separator";
import { useParams } from "react-router-dom";
import CenteredMessage from "../components/CenteredMessage";
import { getToken } from "../LocalStorage";
import ApiService from "../ApiService";
import { ImageInterface } from "../components/imagenesAttacher";


const Editar = (props: any) => {
    const {idProd} = useParams();
    const [loading, setLoading] = useState(true);
    const [producto, setProducto] = useState(null);
    const [imagenes, setImagenes] = useState(Array<ImageInterface>);
    const [datosContacto, setDatosContacto] = useState(null);

    useEffect(() => {
        console.log('editar prod #', idProd);
        fetchProducto(parseInt(idProd || '0'));
    },[idProd]);

    const fetchProducto = async (idProd: number) => {
        const tok = getToken() || '';
        let res = await (new ApiService()).getOneProductoMio(tok, idProd) as any;
        if ( res.err === 0){
            console.log('prod:', res.data);
            setProducto(res.data.producto);
            //.imagenes
            setImagenes(res.data.imagenes);
            setDatosContacto(res.data.vendedor);
            fetchDatosContacto(idProd);
        }
    }

    const fetchDatosContacto = async (idProd: number) => {
        const tok = getToken() || '';
        let res = await (new ApiService()).getDatosContacto(tok, idProd) as any;
        if ( res.err === 0){
            console.log('prod:', res.data);
            setDatosContacto(res.data.datos);
            setLoading(false);
        }
    }
        
    return (<div className="flexColumn" style={{width: '80%', }} >
                
                
                {(loading === true)?
                    <CenteredMessage>Cargando...</CenteredMessage>
                :
                    <ProductoDatos mode="editar" tituloBoton="Guardar" 
                        imagenes={imagenes} producto={producto}
                        datosContacto={datosContacto}
                        onDone={(producto: any) => {
                        console.log('editando:', producto);
                    }}/>
                }


                
                <Separator separacion={30} />
            </div>    
    )
}

export default Editar;