import React from "react";
import { Link, useNavigate } from "react-router-dom";
import '../App.css';
import '../css/userMenu.css';
import Separator from "./Separator";

const MenuGenerico= (props: any) => {
    const navigate = useNavigate();

    const navegar = (ruta: string) => {
        if ( props.onChange){
            props.onChange(ruta);
            navigate(ruta);
        }else{
            navigate(ruta);
        }
    }

    return (
        <div>
            <div className="userMenu simpleBorder" style={{display: props.visible ? 'block' : 'none' }}>
                {props?.children}
            </div>
            <div className="overlay" onClick={() => {
                props.onChange('');
            }} style={{display: props.visible ? 'block' : 'none' }}></div>
        </div>);
}

export default MenuGenerico;
//https://www.freecodecamp.org/news/how-to-build-a-modal-with-javascript/
//https://www.freecodecamp.org/news/how-to-build-a-modal-with-javascript/