import React, { useState } from 'react';
import userIcon from '../assets/icons/user.png';
import bookmarkIcon from '../assets/icons/bookmarks.png';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import UserMenu from './UserMenu';

function TopBar(){
    const navigate = useNavigate();
    const [visibleMenu, setVisibleMenu] = useState(false);

    const toggleUserMenu = () =>{
        setVisibleMenu(!visibleMenu);
    };

    return (<div className='topBar flexRow centered'>
        <div className='leftAlign leftPosition' style={{padding:5}}>
            <img src="https://nubedeventas.com/ndv/logo.png" className='logoImage' alt="logo" 
                onClick={() => {
                    navigate("/");
                }}
            />
        </div>
        {/* <Search placeholder="Encuentra lo que buscas ..." onClick={(query: string) => {
            //alert(query);
            navigate("/ndv/search/" + query);
        }}/> */}
        <div className='rightPosition rightAlign flexRow'>
            <a href="https://play.google.com/store/apps/details?id=com.nubedeventas.ndv" target='_blank'>
                <img 
                    style={{height: 32, padding: 0, marginRight: 5,marginTop: 2.5,}}
                    src="https://images.nubedeventas.com/uploads/googleplay.jpg" alt="imagen"/>
            </a>

            <button style={{backgroundColor:'green', borderRadius: 10}} onClick={() => {
                navigate('/ndv/publicar')
            }}>
                Vender
            </button>
            <img alt="favoritos" src={bookmarkIcon} className="icono" style={{padding: 5}}
                onClick={() => {
                    navigate('/ndv/saves');
                }}
            />


            <img alt="menu" src={userIcon} className="icono" style={{padding: 5}}
                onClick={() => {
                    toggleUserMenu();
                }}
            />
            {/* menu de usuario al hacer click en icono de user */}
            <UserMenu visible={visibleMenu} onChange={(ruta:string)=>{
                setVisibleMenu(false);
            }}/>
        </div>
    </div>)
};

export default TopBar;