import React, { useState } from "react";
import CenteredMessage from "../components/CenteredMessage";
import Separator from "../components/Separator";
import { useNavigate } from "react-router-dom";
import MenuGenerico from "../components/MenuGenerico";
import Policy from "../menuComponents/Policy";
import Rules from "../menuComponents/Rules";
import Tos from "../menuComponents/Tos";

const AyudaPage = (props: any) => {
    const navigate = useNavigate();
    const [visibleMenu, setVisibleMenu] = useState(false);
    const [menuMode, setMenuMode] = useState("");

    const toggleUserMenu = () =>{
        setVisibleMenu(!visibleMenu);
    };

    return  (<div style={{position: 'absolute', top: '10%', width: '80%'}}>
                <CenteredMessage>
                    <div className="flexColumn">
                        <h2>¿Consultas?¿Quejas?</h2>
                        <Separator separacion={30} />
                        <p>Escribenos a <b style={{textDecorationLine: "underline"}}>cuentas(arroba)nubedeventas.com</b></p>
                        <br/>
                        <div onClick={() => {
                            setMenuMode("policy");
                            setVisibleMenu(true);
                        }}>
                            <p style={{textDecorationLine: "underline"}}>Lea nuestra politica de privacidad</p>
                        </div>

                        <div onClick={() => {
                            setMenuMode("rules");
                            setVisibleMenu(true);
                        }}>
                            <p style={{textDecorationLine: "underline"}}>Lea las reglas del sitio</p>
                        </div>

                        <div onClick={() => {
                            setMenuMode("tos");
                            setVisibleMenu(true);
                        }}>
                            <p style={{textDecorationLine: "underline"}}>Lea los terminos del servicio</p>
                        </div>
                        
                        <MenuGenerico visible={visibleMenu} onChange={(ruta:string)=>{
                            setVisibleMenu(false);
                        }}>
                            {(menuMode == "policy")?
                            <Policy />
                            :null}

                            {(menuMode == "tos")?
                            <Tos />
                            :null}

                            {(menuMode == "rules")?
                            <Rules />
                            :null}

                        </MenuGenerico>
                    </div>
                    
                </CenteredMessage>
            </div>)
}

export default AyudaPage;