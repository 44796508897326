export default function Rules(){
    return <div style={{ 'display': 'flex','flexDirection': 'column','height': '100%', "overflowY": "scroll"}}>
<div>
    <h1>Reglas</h1>

    <b>Queda prohibido dentro de la app la utilizacion de imagenes que sean:</b>
    <div>
        <br/>
        <br/>- ofensivas / que discriminen / burlas.
        <br/>- grotescas / sangre / daños.
        <br/>- no aptas para todo el publico (imagenes para solo adultos).
        <br/>- datos personales de terceros dentro de las imagenes.
        <br/>- de partidos politicos.
        <br/>- de personas menores de edad.
        <br/>- con derechos de autor y usted no posea los derechos sobre las mismas.
    </div>
    {/* <Separator/> */}
    <b>Respecto a los pos de las publicaciones</b>
    <div>
        No estan permitidos los insultos, discursos de odio, difamaciones,
        impersonificaciones, datos personales de terceros, ni nada que pueda
        considerarse ilegal en argentina.
    </div>
    {/* <Separator /> */}
    <b>Productos prohibidos</b>
    <div>
        No esta permitido publicar entradas a eventos, armas, cigarrillos/tabaco, remedios/medicamentos, alcohol, bebidas alcoholicas.
        <br/>No esta permitido publicar productos que sean imitaciones ni cosas que violen el copyright.
        <br/>No esta permitido publicar seres vivos de ningun tipo.(perros, gatos, etc)
        <br/>No esta permitido publicar nada que pueda considerarse ilegal en argentina.
    </div>
    <br/>
    <br/>
    <br/>
</div>
</div>
}