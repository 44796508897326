import React, { useEffect, useState } from "react";
import Separator from "../components/Separator";
import { getToken } from "../LocalStorage";
import ApiService from "../ApiService";
import CenteredMessage from "../components/CenteredMessage";
import '../css/panelUsuario.css';
import { useNavigate } from "react-router-dom";

const PanelUsuario = (props: any) => {
    const navigate = useNavigate();

    const [productos,setProductos] = useState(Array<any>);

    const fetchProductos = async  () => {
        const tok = getToken() || '';
        let res = await (new ApiService()).getProductosUsuariosPanel(tok) as any;
        if ( res.err === 0){
            setProductos(res.data.productos);
        }
    }

    const limitChars = (data: string, limite: number) => {
        if ( data === null || data === '')
            return data;
        if ( data.length > limite){
            return data.substring(0,limite) + "...";
        }else{
            return data;
        }
    }

    useEffect(() => {
        fetchProductos();
        // eslint-disable-next-line
    },[]);

    const pausar = async (idProd: number) => {
        const tok = getToken() || '';
        let res = await (new ApiService()).pauseProducto(tok, idProd) as any;
        if ( res.err === 0){
            fetchProductos();
        }
    }

    const activar = async (idProd: number) => {
        const tok = getToken() || '';
        let res = await (new ApiService()).reactivateProducto(tok, idProd) as any;
        if ( res.err === 0){
            fetchProductos();
        }
    }
    
    const visitProducto = (idProd: number) => {
        navigate('/ndv/producto/' + idProd);
    }

    const editarProducto = (idProd: number) => {
        navigate('/ndv/editar/' + idProd);
    }

    return (<div className="flexColumn" style={{width: '80%', }} >
                <h2>Administrar Productos</h2>
                {(productos.length === 0)?
                    <CenteredMessage>No tienes productos publicados</CenteredMessage>
                :null}

                <div className="flexColumn">
                {(productos.length > 0)?
                    productos.map((prod: any)=> {
                        return <div key={"prod_" + prod.id} className="flexRow producto">
                            <img onClick={()=>{
                                visitProducto(prod.id);
                            }} src={prod.thumbnail} className="imagenChica" style={{padding: 10, marginBottom: 10}}/>
                            <div className="flexColumn" style={{flex:1,}}>
                                <p style={{textDecoration: 'underline'}} onClick={()=>{
                                    visitProducto(prod.id);
                                }}>{ limitChars(prod.titulo,80)} <i style={{float:'right', marginRight: 10, }}>(${prod.precio})</i></p>
                                <div>
                                    <button 
                                        onClick={() => {
                                            editarProducto(prod.id);
                                        }}
                                        className="btnPanel" style={{marginRight: 20}} >Editar</button>

                                    {
                                        (prod.estado === 0)?
                                            <button onClick={() => {
                                                pausar(prod.id);
                                            }} className="btnPanel">Pausar</button>    
                                        :null
                                    }

                                    {/* {   pausado */
                                        (prod.estado === 2)?
                                            <button onClick={() => {
                                                activar(prod.id);
                                            }} className="btnPanel">Activar</button>    
                                        :null
                                    }

                                    {   //borrado
                                        (prod.estado === 1)?
                                            <b>Borrado</b>
                                        :null
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    })
                :null}
                </div>
                <Separator separacion={30} />
            </div>    
    )
}

export default PanelUsuario;