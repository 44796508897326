import React from "react";
import { useNavigate } from "react-router-dom";
import '../css/listadoProducto.css';

function ListadoProductos(props: any){
    const navigate = useNavigate();
    //console.log('props listado productos:', props);
    const limitChars = (data: string, limite: number) => {
        if ( data === null || data === '')
            return data;
        if ( data.length > limite){
            return data.substring(0,limite) + "...";
        }else{
            return data;
        }
    }

    return (<div className="listadoProductos flexRowWrap">
        {(props.productos?.length > 0)?
            (props.productos?.map( (prod: any) => {
                return (<div key={"prod_" + prod.id} className="productoItem flexColumn"
                    onClick={() => {
                        navigate("/ndv/producto/" + prod.id);
                    }}
                >
                    <span className="title">{limitChars(prod.titulo,45)}</span>
                    <img src={prod.thumbnail} alt={"producto " + prod.id} className="img borderInvisible"/>
                    <span className="precio">${Math.round(prod.precio)}</span>
                </div>)
            }))
        :null
        }
    </div>)
}

export default ListadoProductos;