import React from "react";
import { Link, useNavigate } from "react-router-dom";
import '../App.css';
import '../css/userMenu.css';
import Separator from "./Separator";

const UserMenu= (props: any) => {
    const navigate = useNavigate();

    const navegar = (ruta: string) => {
        if ( props.onChange){
            props.onChange(ruta);
            navigate(ruta);
        }else{
            navigate(ruta);
        }
    }

    return (
        <div>
            <div className="userMenu simpleBorder" style={{display: props.visible ? 'block' : 'none' }}>
                <div className="flexColumn">
                    <u className="centered">Menu</u><br/>
                    
                    <Separator separacion={30} />

                    <button className="btnMenu" onClick={() => {
                        navegar("/ndv/panel");
                    }}  >Administrar Productos</button><br/>

                    <Separator separacion={30} />

                    <button className="btnMenu" onClick={() => {
                        navegar("/ndv/settings");
                    }}  >Configuracion</button><br/>
                    
                    <Separator separacion={30} />

                    <button className="btnMenu" onClick={() => {
                        navegar('/ndv/ayuda');
                    }}>Ayuda</button>
                </div>
            </div>
            <div className="overlay" onClick={() => {
                props.onChange('');
            }} style={{display: props.visible ? 'block' : 'none' }}></div>
        </div>);
}

export default UserMenu;
//https://www.freecodecamp.org/news/how-to-build-a-modal-with-javascript/
//https://www.freecodecamp.org/news/how-to-build-a-modal-with-javascript/