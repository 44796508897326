import React, { useEffect, useState } from "react";
import ApiService from "../ApiService";
import { getToken, setToken } from "../LocalStorage";

const TokenChecker= (props : any) => {
    useEffect(() => {
        let tok = getToken();
        //validar/pedir nuevo token
        (new ApiService()).doAnonLogin(tok || '').then((data1: any) => {
            console.log("data1:", data1);
            //guardarlo en localstorage.
            setToken(data1.data.token);
            if ( props.onToken){
                props.onToken(data1.data.token);
            }
        }).catch(err => {
            console.log(err);
            props.showModal("Ha ocurrido un error");
        })
    },[]);  
    return <></>;
};

export default TokenChecker;