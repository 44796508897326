import React, { useEffect, useState } from "react";
import CenteredMessage from "../components/CenteredMessage";
import ApiService from "../ApiService";
import ListadoSaves from "../components/ListadoSaves";
import { getToken } from "../LocalStorage";

const SavesList = (props: any) => {
    //const {query} = useParams();
    const [loading,setLoading] = useState(true);
    const [saves, setSaves] = useState([]);
    const [productos, setProductos] = useState([]);

    useEffect(() => {
        //validar/pedir nuevo token
        let tok = getToken() || '';
        (new ApiService()).getSaves(tok).then((data1: any) => {
            console.log("data1:", data1);
            setLoading(false);
            setSaves(data1.data.saves);
            setProductos(data1.data.productos);
        }).catch(err => {
            console.log(err);
            props.showModal("Ha ocurrido un error");
        })
    },[props]);


    if ( loading){
        return (<div style={{flex:1}}>
            <CenteredMessage>Cargando Favoritos...</CenteredMessage>
        </div>    
)
    }else{
        return (<div>
            <h2>Listado de Favoritos</h2>
            {(productos.length === 0)?
                <CenteredMessage>Aca apareceran los productos que viste los datos de contacto</CenteredMessage>
                :
                <ListadoSaves saves={saves} productos={productos} />
            }
            
        </div>    
)
    }
    
}

export default SavesList;