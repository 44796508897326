import React from "react";
import userIcon from '../assets/icons/user.png';

function Avatar({pathImagen = ''}){
    return (
        <div style={{padding:10}}>
            <img alt="avatar de usuario"
                src={pathImagen || userIcon}
                className="avatar rounded"
            />
        </div>
    )
}

export default Avatar;