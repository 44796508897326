import React from "react";

import ProductoDatos from "../components/ProductoDatos";
import Separator from "../components/Separator";


const Publicar = (props: any) => {


        
    return (<div className="flexColumn" style={{width: '80%', }} >
                <ProductoDatos mode="publicar" tituloBoton="Publicar" onDone={(producto: any) => {
                    console.log('publicando:', producto);
                }}/>
                <Separator separacion={30} />
            </div>    
    )
}

export default Publicar;