import React, { useState } from 'react';
import './App.css';
import Separator from './components/Separator';
import TopBar from './components/TopBar';
import RouterWeb from './Router';
import Modal from './components/Modal';
import TokenChecker from './components/TokenChecker';



function App() {
  const [modalChildren, setModalChildren] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);

  const showModal = (children: any) => {
    setModalChildren(children);
    setVisibleModal(true);
  }

  //console.log('prod?:',process.env.NODE_ENV);

  if (process.env.NODE_ENV === 'production') {
    // disable del console.log, warn, info, etc en production.
    window.console.log = () => {};
    window.console.warn = () => {};
    window.console.error = () => {};
    window.console.info = () => {};
  }

  return (
    <div className="flexColumn centered flex1">     
      <RouterWeb showModal={showModal}>  
        {/* barra top de navegacion */}
        <TopBar />
        <TokenChecker showModal={showModal} />
        {/* main profile screen */}
      
        <Separator separacion="10" />
        {/* modal */}
        
        <Modal visible={visibleModal} onChange={() => setVisibleModal(false)} >{modalChildren}</Modal>
        
        
        
      </RouterWeb>
    </div>
  );
}

export default App;