function getToken(){
    return localStorage.getItem('token');
}

function setToken(tok: string){
    localStorage.setItem('token',tok);
}


function clearLocalStorage(){
    localStorage.clear();
}

export{
    getToken,
    setToken,
    clearLocalStorage
}