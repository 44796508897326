export default function HiStatsCounter(){
    return <></>;
    // function start(){
    //     let _Hasync= [];
    //     _Hasync.push(['Histats.start', '1,4875746,4,511,95,18,00000000']);
    //     _Hasync.push(['Histats.fasi', '1']);
    //     _Hasync.push(['Histats.track_hits', '']);
                    
    //     let hs = document.createElement('script'); hs.type = 'text/javascript'; hs.async = true;
    //     hs.src = ('//s10.histats.com/js15_as.js');
    //     (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(hs);
                    
    // }
    // start();
    // return  <div>
    //             <div id="histats_counter"></div>
    //         </div>
}