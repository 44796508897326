import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import ApiService from "../ApiService";
import HeaderProducto from "../components/HeaderProducto";
import Config from "../Config";
import DescripcionProducto from "../components/DescripcionProducto";
import { getToken } from "../LocalStorage";
import MenuGenerico from "../components/MenuGenerico";
import ConfirmarRegistro from "../components/ConfirmarRegistro";
import HiStatsCounter from "../stats/HiStatsCounter";

const Producto = (props: any) => {
    const {idProd} = useParams();
    let idProducto = parseInt(idProd as string);
    
    const [producto, setProducto] = useState({});
    const [vendedor, setVendedor] = useState({});
    const [loading, setLoading] = useState(false);
    const [visibleMenu, setVisibleMenu] = useState(false);

    useEffect(() => {
        if (loading){return};
        console.log('idProducto inside:', idProducto);
        console.log('loading inside:', loading);
        let tok = getToken() || '';
        (new ApiService()).getOneProducto(tok,idProducto).then((data1: any) => {
            setLoading(true);
            
            if ( data1.err !== 0){
                props.showModal('Ha ocurrido un error');
                console.log('error:' + data1.err);
            }

            let prod = data1?.data?.producto;
            setProducto(prod);

            let vendedor = data1?.data?.vendedor;
            setVendedor(vendedor);
        }).catch((err: any) => {
            console.log(err);
            props.showModal("Ha ocurrido un error");
        })
    },[loading, idProducto, props]);

    const getDatosProductos= (idProd: number) => {
        //useEffect(() => {
            let tok = getToken() || '';
            (new ApiService()).getDatosContacto(tok, idProd).then((data1:any) => {
                console.log('datos de contacto',data1);
                //alert('Datos de contacto:' + data1.data.datos);
                //TODO: mostrarlo en un modal.
                let datos = data1.data.datos;
                if ( datos === ''){
                    datos = "Sin datos de contacto";
                }

                let msg = '<b>Datos de contacto</b>\n\n\n'+
                    datos + "\n\n" +
                    '<b>No olvide mencionar que vio el producto en Nube de Ventas</b>'
                ;
                props.showModal(msg);

            }).catch(err => {
                console.log(err);
                props.showModal("Ha ocurrido un error");
            });
        //},[]);
    };

    //let datosProducto = DatosMock.productos.filter(item => item.id === idProducto);
    return (<div style={{width: '100%'}} className="flexColumn centered">
                <HeaderProducto producto={producto} vendedor={vendedor} 
                    onClick={(idProd: number) => {
                        setVisibleMenu(true);
                    }}
                /> 
                <MenuGenerico visible={visibleMenu} onChange={(ruta:string)=>{
                    setVisibleMenu(false);
                }}>
                    <ConfirmarRegistro onAccept={() => {
                        //debugger;
                        if ( idProducto === undefined){
                            return;
                        }
                        //TODO: mostrar datos de contacto.
                        getDatosProductos(idProducto);

                        setVisibleMenu(false);
                    }} onReject={() => {
                        setVisibleMenu(false);
                    }}/>
                </MenuGenerico>   
                <DescripcionProducto producto={producto}  />
                <HiStatsCounter />
            </div>)
}

export default Producto;