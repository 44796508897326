export default function Tos(){
    return <div style={{ 'display': 'flex','flexDirection': 'column','height': '100%', "overflowY": "scroll"}}>
    <h1>Terminos del servicio</h1>
    <b>Responsabilidades del usuario</b>
    <p>                        
    El usuario publicara productos que sean de venta legal en al argentina. No publicara nada ilegal.
Tratara cordialmente y con respeto a los demas integrantes de la comunidad. 
No insultara a nadie dentro de la app.
No subira imagenes que no respeten las reglas de la comunidad.
    </p><br/>
    <b>Responsabilidades de la app</b>
    <p>
    La app se compromete a echar a los usuarios que vulneren los terminos del servicio, politicas o reglas de la app.
Tambien la app se compromete a procesar las denuncias/reportes de los usuarios sobre productos que no cumplan los Terminos o reglas de la app.                      
    </p>
    <br/>
    <b>Acerca de pagos</b>
    <p>
    La app no participa de las ventas, solo exhibe los productos para ayudar a los usuarios a encontrar compradores para sus productos.

No cobramos ni un centavo a los usuarios por las publicaciones.

Cualquier pago para comprar productos es solo un acuerdo entre usuarios particulares/negocios/tiendas ajenos, reconociendo por parte del usuario que los creadores de la app no le estan vendiendo nada ni cobrando por el servicio.

Tome las precauciones que considere necesarias al tratar con otras personas tanto a nivel seguridad fisica como a nivel de salud por el covid.

La App no chequea datos de ninguna de las partes involucradas en las transacciones, solo se limita a ser una especie de catalogo de productos. 

No nos hacemos responsables de ningun daño, perjuicio, perdida, robo, etc.

Cualquier accion que suceda fuera de la app en el mundo real es solo responsabilidad del comprador y/o del vendedor.
    </p>
    <br/>
    <br/>
    <br/>

</div>
}