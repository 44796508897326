import React from "react";
import { useNavigate } from "react-router-dom";
import '../css/listadoSaves.css';
import '../css/listadoProducto.css';

function ListadoSaves(props: any){
    const navigate = useNavigate();
    console.log('props listado saves:', props);
    
    const limitChars = (data: string, limite: number) => {
        if ( data === null || data === '')
            return data;
        if ( data.length > limite){
            return data.substring(0,limite) + "...";
        }else{
            return data;
        }
    }

    return (<div className="listadoProductos flexRowWrap">
        {(props.saves?.length > 0)?
            (props.saves?.map( (save: any) => {
                console.log('save:', save);
                let prod = props.productos.filter((item : any) => item.id === save.idProducto)[0];

                if ( prod == null){
                    return <></>
                }else{
                    return (<div key={"prod_" + prod.id} className="productoItem flexColumn"
                        onClick={() => {
                            navigate("/ndv/producto/" + prod.id);
                        }}
                    >
                        <span className="title">{limitChars(prod.titulo,45)}</span>
                        <img className="img" src={prod.thumbnail} alt={"producto " + prod.id} />
                        <span className="precio">${Math.round(prod.precio)}</span>
                    </div>)
                }
                
                
            }))
        :null
        }
    </div>)
}

export default ListadoSaves;