import { useState } from "react";
import Policy from "../menuComponents/Policy";
import Tos from "../menuComponents/Tos";
import Rules from "../menuComponents/Rules";

export default function ConfirmarRegistro(props:any){
    const [menuMode1, setMenuMode1] = useState("");
    console.log("menuMode:", menuMode1)
    if ( menuMode1 === ""){

        return  <div style={{width: '100%', display: "flex", flexDirection: "column"}}>
                <h3>Antes de hacer esta accion debes leer y aceptar esto si estas de acuerdo</h3>
                
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div onClick={() => {
                        setMenuMode1("policy");
                        //setVisibleMenu(true);
                    }}>
                        <p style={{textDecorationLine: "underline"}}>Lea nuestra politica de privacidad</p>
                    </div>

                    <div onClick={() => {
                        setMenuMode1("rules");
                        //setVisibleMenu(true);
                    }}>
                        <p style={{textDecorationLine: "underline"}}>Lea las reglas del sitio</p>
                    </div>

                    <div onClick={() => {
                        setMenuMode1("tos");
                        //setVisibleMenu(true);
                    }}>
                        <p style={{textDecorationLine: "underline"}}>Lea los terminos del servicio</p>
                    </div>
                </div>

                <div style={{display: "flex", flexDirection: "row"}}>
                    <button onClick={() => {
                        if (props.onAccept){
                            props.onAccept();
                        }else{
                            console.log("No esta implementado el accept");
                        }
                    }}
                    style={{backgroundColor: "green", color: "white", width: '50%', height: 40, fontSize: 26}}
                    >Acepto</button>

                    <button onClick={() => {
                        if (props.onReject){
                            props.onReject();
                        }else{
                            console.log("No esta implementado el reject");
                        }
                    }}
                    style={{backgroundColor: "red", color: "white", width: '50%', height: 40, fontSize: 26}}
                    >Rechazo</button>
                </div>
            </div>
    }else{
        return  <div style={{flex:1,maxHeight: '100%', width: '100%', display: 'flex', flexDirection: 'column'}}>
                    <button onClick={() => {
                        setMenuMode1("");
                    }}
                    style={{width: '100%', height: 40, color: 'white', backgroundColor: 'blue', fontSize: 26}}
                    >Volver</button>

                    {(menuMode1 == "policy")?
                        <Policy />
                    :null}

                    {(menuMode1 == "tos")?
                        <Tos />
                    :null}

                    {(menuMode1 == "rules")?
                        <Rules />
                    :null}
                </div>
    }

    


}