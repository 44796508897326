import React from "react";
import '../css/producto.css';

const DescripcionProducto = (props: any) => {
    return(<div className="descripcionProducto">
        <p dangerouslySetInnerHTML={{__html: fixStringJumps(props.producto?.descripcion)}} />
    </div>)
}

function fixStringJumps(data: String){
    if (data === undefined){
        return ''
    };

    if (data === null || data === '' ){
        return '';
    }else{
        console.log('data:',data);

        let dat1 = data.replace(/\n/g, "<br />")
        return dat1;
    }
}

export default DescripcionProducto;