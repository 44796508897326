import React, { useEffect, useState } from "react";
import ApiService from "../ApiService";
import ListadoProductos from "../components/ListadoProductos";
import Separator from "../components/Separator";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from "react-router-dom";
import HiStatsCounter from "../stats/HiStatsCounter";

const HomePage = (props: any) => {
    // let token = '';
    //const [token,setToken] = useState('');
    const navigate = useNavigate();
    const [more,setMore] = useState(true);//si no hay mas resultados
    const [page,setPage] = useState(0);
    const [loading,setLoading] = useState(false);
    const [productos, setProductos] = useState([]);
    console.log('props homepage:', props)
    //const observerTarget = useRef(null);

    const fetchData = (page: number = 0 ) => {
        console.log('Loading page:', page);
        if ( loading === true) 
            return;
        setLoading(true);

        if ( page === 0){
            setProductos([]);
        }

        (new ApiService()).getMainPage(page).then((data1: any) => {
            let arrTemp:any = productos;//guardo los nuevos productos aqui.
            
            if (data1.data?.productos.length === 0){
                setMore(false);
            }

            for (let i=0; i < data1.data?.productos.length; i++){
                let element = data1.data?.productos[i];
                //buscar si ya existia en la lista
                let found = arrTemp.filter( (item:any) => {
                    //console.log('item.id:', item.id);
                    //console.log('element.id:', element.id);
                    return (item.id === element.id)
                })
                //debugger;

                if ( found.length === 0){
                    //setProductos(productos.concat(element));
                    arrTemp.push(element);
                    //console.log('arrTemp:', arrTemp.length);
                }else{
                    console.log('found:', found);
                }
            }
            
            arrTemp.sort( (a: any,b: any) => {
                if ( a.id === b.id ) return 0;
                if ( a.id > b.id ) 
                    return -1
                else
                    return +1;
            })
            console.log('arrTemp:', arrTemp.length);
            setProductos(arrTemp);
            //setProductos(arr);
            page = page + 1;
            setPage(page);
            console.log('page update:',page);
            setLoading(false);
            //}, 250);
            
        }).catch(err => {
            props.showModal("Ha ocurrido un error");
            //setPage(page++);
            //console.log('page update:',page);
            setLoading(false);
        })
    }


    

    useEffect( () => {
        console.log("API: getMainPage 0");
        fetchData(0);
        // eslint-disable-next-line 
    },[])//no agregar productos como dep ni fetchData.

    const goVender = () => {
        navigate('/ndv/publicar');
    }

    return (<div>
                {/* <Noticias noticias={Config.default.NoticiasTest} /> */}
                {/* <Separator separacion={0}/> */}
                <h2 className="centered" style={{textDecoration: 'underline dotted blue'}} onClick={() => {
                    goVender();            
                }}>Venda sin pagar comisiones</h2>

                <InfiniteScroll
                    style={{marginTop: 0}}
                    dataLength={productos.length}
                    next={() => {
                        setPage(page+1);
                        fetchData(page);
                    }}
                    hasMore={more} // Replace with a condition based on your data source
                    loader={<p>Cargando...</p>}
                    endMessage={<p>No hay mas productos.</p>}
                >
                    <ListadoProductos productos={productos} />        
                </InfiniteScroll>
                <HiStatsCounter />
                <Separator separacion={20} />

            </div>    
    )
}

export default HomePage;