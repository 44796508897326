import React, { useState } from "react";
import ImagenesAttacher, { ImageInterface } from "./imagenesAttacher";
import Separator from "./Separator";
import * as Config from '../Config';
import '../App.css';
import ApiService from "../ApiService";
import CenteredMessage from "./CenteredMessage";
import { getToken } from "../LocalStorage";
import MenuGenerico from "./MenuGenerico";
import ConfirmarRegistro from "./ConfirmarRegistro";

function ProductoDatos(props: any){
    const [idProd, setIdProd] = useState(0);
    const [titulo, setTitulo] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [precio, setPrecio] = useState(0);
    const [datosContacto, setDatosContacto] = useState('');
    const [imagenes, setImagenes] = useState(Array<ImageInterface>);
    const [ocupado, setOcupado] = useState(false);
    const [mode, setMode] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [visibleMenu, setVisibleMenu] = useState(false);


    const handleTitulo = (event: any) => {
        setTitulo(event.target.value);
    }

    const handleDescripcion = (event: any) => {
        setDescripcion(event.target.value);
    }

    const showConfirmar = () => {
        setVisibleMenu(true);
    }

    if ( props.mode === "editar" && loaded === false){
        console.log('props editar:', props);
        setIdProd(props.producto?.id);
        setTitulo(props.producto?.titulo);
        setDescripcion(props.producto?.descripcion);
        setPrecio(props.producto?.precio);
        setDatosContacto(props.datosContacto);
        setImagenes(props.imagenes);
        setLoaded(true);
    }

    const limpiar= () => {
        setTitulo('');
        setDescripcion('');
        setPrecio(0);
        setDatosContacto('');
        setImagenes(new Array<ImageInterface>());
        setMode('');
        setOcupado(false);

    }

    const validar = async (postData: any, imagenesData: Array<ImageInterface>) => {
        console.log('Recibido array de imagenes #',imagenesData.length);
        setOcupado(true);
        let arr= [];
        for(let i=0; i < imagenesData.length;i++){
            let item = imagenesData[i];
            if ( item.pathRel === ''){
                let res = await(new ApiService()).uploadImage(item.b64Data) as any;
                console.log(res);
                item.pathRel = Config.default.BaseUpload + res.nombre;
            }
            arr.push(item.pathRel);
        }
        
        //aca validar que no este vacio el titulo o descripcion.
        if ( postData?.titulo?.trim() == ""){
            alert("El titulo esta vacio, debe escribir algo");
            setOcupado(false);
            return;
        }

        if ( postData?.descripcion?.trim() == ""){
            alert("la descripcion esta vacia, debe escribir algo");
            setOcupado(false);
            return;
        }
        //todo esta ok, publicarlo.
        let tok = getToken() || '';
        if ( postData.id > 0){
            //editar producto.
            let resPublicarEdicion = await (new ApiService()).editarProducto(tok,postData.id,titulo,descripcion, precio
                ,datosContacto, arr.join(',')) as any;
            if ( resPublicarEdicion.err === 0){
                setMode('ok');
            }else{
                setMode('error');
            }
        }else{
            //nueva publicacion   
            let resPublicar = await (new ApiService()).publicar(tok,titulo,descripcion, precio
                ,datosContacto, arr.join(',')) as any;
            if ( resPublicar.err === 0){
                setMode('ok');
            }else{
                setMode('error');
            }
        }
    }

    if ( mode === 'ok'){
        //publicado ok
        return  <div style={{position: 'absolute', top: '40%', width: '80%'}}>
                    <div className="flexColumn">
                        <h2>Producto publicado correctamente</h2>
                        <button className="btnNormal" onClick={limpiar}>¿Publicar otro?</button>
                    </div>
                </div>
    }

    if( mode === 'error'){
        //error al publicar
        return  <div style={{position: 'absolute', top: '40%', width: '80%'}}>
                    <div className="flexColumn">
                        <h2>Ha ocurrido un error</h2>
                        <button className="btnNormal" onClick={limpiar}>¿Publicar otro?</button>
                    </div>
                </div>
    }

    if ( ocupado){
        return  <div style={{position: 'absolute', top: '40%', width: '80%'}}>
                    <CenteredMessage>
                        <h2>Publicando...</h2>
                    </CenteredMessage>
                </div>
    }

    return (
        <div style={{flex:1}} className="flexColumn bordered">
            <h2>Publicar Producto</h2>
            <div className="flexColumn">
                <b>Titulo</b>
                <input type="text" id="titulo" required onChange={(event: any) => {
                    //console.log(event.target.value);
                    handleTitulo(event);
                }} placeholder="¿Que es lo que vendes?"
                    value={titulo}
                />
            </div>

            <div className="flexColumn">
                <b>Descripcion</b>
                <textarea id="descripcion" rows={8} required onChange={(event: any) => {
                    //console.log(event.target.value);
                    handleDescripcion(event);
                }} value={descripcion} placeholder="Describe detalladamente lo que vendes"></textarea>
            </div>

            <div className="flexColumn">
                <b>Precio</b>
                <input type="number" id="precio" required onChange={() => {
                    let obj = document.getElementById('precio') as HTMLInputElement;
                    console.log('precio:', obj.valueAsNumber);
                    if ( ! isNaN(obj.valueAsNumber))
                        setPrecio(obj.valueAsNumber);
                }} placeholder="¿cuanto vale?" value={precio}/>
            </div>

            <div className="flexColumn">
                <b>Datos de contacto</b>
                <input type="text" id="datosContacto" required onChange={(event: any) => {
                    console.log(event.target.value);
                    setDatosContacto(event.target.value);
                }} value={datosContacto} placeholder="¿Como se contactaran contigo los compradores? ingrese email, whatsapp, etc"/>
            </div>

            <Separator separacion={10} />

            <ImagenesAttacher images={imagenes} onChange={(imgs: Array<ImageInterface>) => {
                console.log('imagenes onChange:', imgs.length);
                setImagenes(imgs)
            }}/>

            <MenuGenerico visible={visibleMenu} onChange={(ruta:string)=>{
                setVisibleMenu(false);
            }}>
                <ConfirmarRegistro onAccept={() => {
                    //TODO: hacer validacion
                    validar({
                        titulo: titulo,
                        descripcion: descripcion,
                        precio: precio,
                        datosContacto: datosContacto,
                    }, imagenes);

                    setVisibleMenu(false);
                }} onReject={() => {
                    setVisibleMenu(false);
                }}/>
            </MenuGenerico>

            {( props.mode === "publicar" )?
                <button className="btnNormal" onClick={() => {
                    //if ( props.onDone){
                        /*validar({
                            titulo: titulo,
                            descripcion: descripcion,
                            precio: precio,
                            datosContacto: datosContacto,
                        }, imagenes)*/
                    //}
                    showConfirmar();
                }}>{props.tituloBoton || 'Publicar'}</button>
            : null}


            {( props.mode === "editar" )?
                <button className="btnNormal" onClick={() => {
                    //if ( props.onDone){
                    validar({
                        id: idProd,
                        titulo: titulo,
                        descripcion: descripcion,
                        precio: precio,
                        datosContacto: datosContacto,
                    }, imagenes)

                    console.log({
                        id: idProd,
                        titulo: titulo,
                        descripcion: descripcion,
                        precio: precio,
                        datosContacto: datosContacto,
                        imagenes: imagenes,
                    })
                    //}
                }}>{props.tituloBoton || 'Editar'}</button>
            : null}
            
        </div>
    )
}

export default ProductoDatos;