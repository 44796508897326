import React from "react";
import { Link } from "react-router-dom";
import CenteredMessage from "../components/CenteredMessage";
import { useParams } from 'react-router-dom'

const SearchResults = (props: any) => {
    const {query} = useParams();

    return (<div>
                <CenteredMessage>Resultados De:{query}</CenteredMessage>
                <Link to="/">HomePage</Link>
            </div>    
    )
}

export default SearchResults;