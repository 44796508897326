
export default function Policy(){
    return (
        <div style={{ 'display': 'flex','flexDirection': 'column','height': '100%', "overflowY": "scroll"}}>
            <h1>Politica de Privacidad</h1>
            <p>Ultima revision 15 octubre 2021. </p>
            <p>
                A nosotros los desarrolladores de "nube de ventas" nos importa la privacidad de los usuarios. Por eso en este documento especificamos que datos recolectamos y como los usamos.
            </p>

            <div>
                <b>
                    ¿que datos recolectamos? 
                </b>
                <p>solo datos como apodo, ip, datos productos publicados como ser titulo, precio, descripcion, imagenes de dicho producto.
                    Tambien recolectamos, para mostrarselo a su comprador/vendedor, pero no usamos ni leemos los chats entre usuarios. O lo que es lo mismo, los mensajes se almacenan en un servidor para que esten siempre disponibles solo para los usuarios involucrados en dicha conversacion.
                </p>
            </div>

            <div>
                <b>¿Por que recolectamos dichos datos?</b>
                <p>
                    para que funcione la app y mejorar la experiencia del usuario.
                </p>
            </div>
        <div>
        <b>
            ¿Como usamos los datos recolectados?
        </b>
        <p>
        los usamos para reunir a los usuarios interesados en realizar la compra/venta entre si. Dichos datos son proporcionados de forma voluntaria por los usuarios para realizar la transaccion. Los datos de contacto no son vendidos a terceros.

        El usuario nos da el permiso de uso de las imagenes suministradas para mostrarlas dentro de la app, siendo que las imagenes suministradas poseen por parte del usuario derechos y solo suministra imagenes capturadas por el mismo usuario o imagenes que el usuario tenga derechos sobre el mismo.

        Nosotros no hacemos uso de ninguna tarjeta de credito/debito ni dinero fisico dentro de la app. No le cobramos nada a los usuarios por publicar ni por vender.

        Cualquier intercambio de dinero por cualquier medio, es de total responsabilidad y acuerdo entre los usuarios de la app, reconociendo que los desarrolladores no intervenimos en este proceso ni le cobramos.
        </p>
        </div>
        <div>
        <b>
            Extras:
        </b>
        <p>
            Cualquier marca mencionada en los productos o imagen subida es propiedad de su respectivo dueño. Nosotros solo nos limitamos a mostrar de forma agradable los productos en un listado/catalogo para su consulta por parte de los usuarios. Cualquier pregunta que tengan pueden escribirme directamente a "cuentas@nubedeventas.com"
        </p>
        </div>
        <div>
        <b>
            ¿Que datos son recolectados por terceros?
        </b>
        <p>
            No se usan servicios de terceros, por lo que no recolectan datos sobre ustedes.
        </p>
        </div>
        <div>
        <b>
            Politica respecto a chicos:
        </b>
        <p>
            Esta app no esta orientada a que los chicos la usen, si detectamos que alguien menor a 13 años llegase a usar la app, procederemos a borrar su cuenta y datos respecto al mismo.
        </p>
        </div>
        <div>
        <b>
            Cambios futuros en la politica de privacidad:
        </b>
        <p>
            Si en el futuro hay algun cambio a la politica de privacidad, se lo haremos saber y le daremos la posibilidad de aceptarla o rechazarla dentro de la app.
        </p>
        </div>
        <div>
        <b>
            Contactandonos:
        </b>
        <p>
            Si tienen alguna duda pueden contactarnos a cuentas@nubedeventas.com
        </p>
</div>
</div>

)}
