
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

export interface ImageInterface {
    id: string;
    b64Data: string;
    timestamp: Date;
    pathRel: string;
}


const convertToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
};

const ImagenesAttacher = (props: any) => {
    const [images, setImages] = useState(Array<ImageInterface>())

    useEffect(() => {
        setImages(props.images);
        console.log('images start up #', images.length);
    },[images])

    const borrar = (itemId : string) => {
        console.log('borrar:',itemId);
        /*setImages( images => 
            images.filter((it: ImageInterface) => {
                return (it.id !== itemId);
            }) 
        );*/
        let nuevos: Array<ImageInterface> = [];
        nuevos = images.filter((it: ImageInterface) => {
            return (it.id !== itemId);
        });
        console.log('borrado #', nuevos.length);
        setImages(nuevos);
        console.log('borrado #', images.length);

        if ( props.onChange){
            //mandarle al parent object las imagenes
            console.log('borrado #', nuevos.length);
            props.onChange(nuevos);
        }
    }
    
    return <div className="flexColumn"> 
        <ControlAddImagen onChangeOne={(item: ImageInterface) => {
            let nuevos = [...images, item];
            console.log('update de imagenes #', nuevos.length);
            setImages(nuevos);
            console.log('update de imagenes #', images.length);
            if ( props.onChange){
                //mandarle al parent object las imagenes
                console.log('update de imagenes #', nuevos.length);
                props.onChange(nuevos);
            }
        }}
        />
        <ImagesPreview images={images} borrar={borrar}/>
    </div>
}

const ImagesPreview = (props: any) => {
    console.log('images preview count:', props.images?.length);
    return <div className="flexRowWrap centered">
        {props.images?.map((img: ImageInterface) => { 
            return  <div key={img.id} className="flexColumn" style={{padding: 5}}>
                        {(img.pathRel === '')?
                            <img style={{width: 125,height: 125}} src={img.b64Data} alt="producto" />
                        :
                            <img style={{width: 125,height: 125}} src={img.pathRel} alt="producto" />
                        }
                        
                        <button 
                            onClick={() => {
                                props.borrar(img.id);
                            }}
                            style={{position:'relative', top: -10, backgroundColor: 'black', color: 'white'}}>(Borrar)</button>
                    </div>
        })}
    </div>
}

const ControlAddImagen = (props:any) => {
    const showAddDialog= () => {
        const el = document.getElementById("files");
        if (el != null){
            el.click();
        }
    }

    const onSelected = async (ev: any) => {
        let filelist = ev?.target.files;
        let imagenes = Array.from(filelist);
        
        imagenes.forEach((img: any)=> {
            convertToBase64(img).then((datab64 : any) => {
                //console.group(datab64);
                let item : ImageInterface = {
                    id: uuidv4(),
                    b64Data: datab64,
                    timestamp: img.timestamp,
                    pathRel: '',
                }

                if ( props.onChangeOne){
                    console.log('item update #', item.id);
                    props.onChangeOne(item);
                }
        
            }).catch(err => {
                console.log(err);
            }); 
                
        });
        
    }


    return (<div>
                <input type="file" id="files" accept="image/*" style={{display: 'none'}} onChange={onSelected}/>
                <button onClick={showAddDialog}>Agregar Imagen</button>
            </div>
    )
}

export default ImagenesAttacher; 