import React from "react";
import '../App.css';
import '../css/modal.css';

function fixStringJumps(data: String){
    if (data === undefined){
        return ''
    };

    if (data === null || data === '' ){
        return '';
    }else{
        console.log('data:',data);

        let dat1 = data.replace(/\n/g, "<br />")
        return dat1;
    }
}

const Modal= (props: any) => {
    console.log('modal render');
    return (
        <div>
            <div className="modal simpleBorder" style={{display: props.visible ? 'block' : 'none' }}>
                <div>
                    <p dangerouslySetInnerHTML={{__html: fixStringJumps(props.children)}} />
                </div>
            </div>
            <div className="modalOverlay" onClick={() => {
                if ( props.onChange)
                    props.onChange('');
            }} style={{display: props.visible ? 'block' : 'none' }}></div>
        </div>);
}

export default Modal;
//https://www.freecodecamp.org/news/how-to-build-a-modal-with-javascript/
//https://www.freecodecamp.org/news/how-to-build-a-modal-with-javascript/