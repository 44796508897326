import React from "react";


function Separator(props: any){
    let separacion = parseInt(props.separacion || 1);

    return ( <div style={{marginBottom: separacion, width: '100%'}}>
                {'\n'}
             </div>)
}

export default Separator;