import React, { useEffect, useState } from "react";
import ApiService from "../ApiService";
import { getToken } from "../LocalStorage";
import Config from "../Config";
import Separator from "../components/Separator";
import '../App.css';
import { useNavigate } from "react-router-dom";

const Settings = (props: any) => {
    const navigate = useNavigate();

    const [userData,setUserData]:any = useState({});
    //const [loading, setLoading] = useState(true);
    
    
    const [msgError,setMsgError] = useState('');
    useEffect(() => {
        fetchUserSettings();
    },[]);

    


    const ApodoComponent= (props: any) => {
        const [apodo,setApodo] = useState(props.apodo || '');

        const handleApodoChange = (event: any) =>{
            setApodo(event.target.value);
        }

        const saveApodo = () => {
            let tok = getToken() || '';
    
            (new ApiService()).saveApodo(tok, apodo).then((data1: any) => {
                if ( data1.err === 0){
                    setMsgError("Apodo guardado correctamente");
                }else if (data1.err === 2){
                    setMsgError("El apodo esta en uso por otro usuario, prueba otro distinto");
                }
    
            }).catch((err:any) => {
                console.log(err);
                //setLoading(false);
            })  
        }
    
        return <div className="flexRow centered">
            <b>Apodo:</b>
            <input autoFocus name="firstName" type="text" onChange={handleApodoChange} value={apodo}/>
            <button className="btnNormal" onClick={() => saveApodo()}>Guardar</button>
        </div>
    }

    const AvatarUsuarioComponent = (props: any) => {
        const [avatar,setAvatar] = useState(props.pathRel || Config.SinImagen);

        const finishAvatarUpdate= (avatar2: string) => {
            setAvatar(avatar2);//url
    
            console.log('avatar2 url:', avatar2);
    
            let tok = getToken();
            (new ApiService()).setAvatar(tok, avatar2).then((data1: any) => {
                console.log(data1);
                setMsgError("Imagen de perfil actualizada");
                fetchUserSettings();
            }).catch((err: any) => {
                console.log(err);
                setMsgError("Ha ocurrido un error");
            })
        }
        
        const convertToBase64 = (file: any) => {
            return new Promise((resolve, reject) => {
              const fileReader = new FileReader();
              fileReader.readAsDataURL(file);
              fileReader.onload = () => {
                resolve(fileReader.result);
              };
              fileReader.onerror = (error) => {
                reject(error);
              };
            });
        };
    
        const seleccionarImagen = () => {
            let inputImg = document.getElementById("myImage");
            //debugger;
            if ( inputImg != null){
                inputImg.click();
            }
        }
    
        const Avatar2 = (props: any) => {
            console.log('pathImagen:',props.pathRel);
            //debugger;
            return (
                <div style={{padding:10}}>
                    <img alt="avatar de usuario"
                        src={props.pathRel || Config.SinAvatarUsuario}
                        className="avatar rounded"
                    />
                </div>
            )
        }
    
        
        
        return <div className="flexRow centered">
            <Avatar2 pathRel={avatar}/>
            <button className="btnNormal" onClick={() => {
                seleccionarImagen();
            }}>Cambiar Imagen</button>
             <input
                type="file"
                id="myImage"
                style={{display: 'none'}}
                onChange={(event: any) => {
                    let sel = event.target.files[0];
                    console.log(sel);
                    //setAvatar(sel);
                    convertToBase64(sel).then((datab64: any) => {
                        console.log(datab64);
                        setMsgError("Uploading...");

                        (new ApiService()).uploadImage(datab64).then((dataImagen: any) => {
                            console.log('dataImagen:', dataImagen);
                            //mandar peticion de update de avatar al backend.
                            //debugger;
                            setMsgError("");
                            let nuevoAvatar = Config.BaseUpload + dataImagen.nombre;
                            //setAvatar(nuevoAvatar);
                            //console.log('avatar:', avatar);
                            finishAvatarUpdate(nuevoAvatar);
                        }).catch((err: any) => { 
                            console.log(err);
                            setMsgError("error de subida de la imagen");
                        })
                    }).catch((err: any) => {
                        console.log(err);
                        setMsgError("error de lectura de imagen");
                    })
                }}
            ></input>
        </div>
    }

    
    const fetchUserSettings = () => {
        let tok = getToken() || '';
        (new ApiService()).getUserSettings(tok).then((data1: any) => {
            console.log(data1);
            setUserData(data1.data?.settings);
            //setApodo(data1.data?.settings?.apodo);
            
        }).catch(err => {
            console.log(err);
            
        })  
    }

    // if ( loading === true){
    //     return (<CenteredMessage>Cargando...</CenteredMessage>)
    // }

    const goAyuda= () => {
        navigate('/ndv/ayuda');
    }
    return (<div>
                <div className="flexColumn">
                    <h2>Configuracion de cuenta</h2>
                    <ApodoComponent apodo={userData?.apodo} />
                    <Separator separacion="20" />
                    <AvatarUsuarioComponent pathRel={userData?.pathRel}/>
                    <p>{msgError}</p>
                    <p style={{textDecoration: 'underline dotted blue'}} onClick={() => {
                        goAyuda();
                    }}>¿Consultas?¿Quejas? (click aqui)</p>
                </div>
            </div>    
    )
}



export default Settings;