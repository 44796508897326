import React from "react";
import Vendedor from "./Vendedor";


const HeaderProducto = (props:any) => {
    let producto = props.producto;
    let vendedor = props.vendedor;
    console.log('props header prod:', props);

    return (<div className="headerProducto">
        <div className="imagenytitulo">
            <img src={producto.thumbnail} alt="imagen de producto" 
                className="imagenMediana" style={{marginLeft: 10}}
            />
            <div className="centeredVectical flexColumn datosProducto">
                <h2 style={{width: '100%', fontSize:'2em'}}>{producto.titulo}</h2>
                <p style={{fontSize: '1.6em'}}>Precio: $ <b>{Math.round(producto.precio).toString()}</b></p>
                <button onClick={() => {
                    if ( props.onClick){
                        props.onClick(producto.id);
                    }
                }} className="buttonContactar">Contactar</button>
                <Vendedor user={vendedor} />
            </div>
            
        </div>
    </div>)
}

export default HeaderProducto;