import React from "react";
import '../css/vendedor.css';
import '../App.css';
import { useNavigate } from "react-router-dom";
import Config from '../Config';

const Vendedor = (props:any) => {
    console.log('Vendedor:', props?.user);
    let vendedor = props?.user;
    const navigate = useNavigate();

    return (
        <div className="vendedor flexRow">
            <img src={vendedor.pathRel || Config.SinAvatarUsuario} alt="imagen del vendedor" 
                className="imagenVendedorChica roundedThin" style={{marginLeft: 10}}
                onClick={() => {
                    navigate('/ndv/perfil/' + vendedor.id + "/" + vendedor.apodo);
                }}
            />
            <br/>
            <b className="apodoVendedor centeredVectical" onClick={() => {
                navigate('/ndv/perfil/' + vendedor.id + "/" + vendedor.apodo);
            }}>{vendedor.apodo}({vendedor.reputacion || 0})</b>
        </div>)
};

export default Vendedor;