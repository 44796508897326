//let BasePath = "https://apps.chispeame.com:8121/";
let BasePath = "https://nubedeventas.com:8121/";
//let BasePath = "http://localhost:8121/";
//let testToken = "2a334bd7-e503-4463-a987-3b8e0c9ec2f8";

let SinImagen = "https://images.nubedeventas.com/uploads/sin-imagen.png";
let SinAvatarUsuario = "https://images.nubedeventas.com/uploads/user.png";

let UploadBase64Path = "https://images.nubedeventas.com/b64saveJPG/ndvReact";
let BaseUpload = "https://images.nubedeventas.com/uploads/";

let noticiasTest =[
    {
        id: 1,
        fecha: new Date(),
        texto: 'nueva noticia de hoy',
        url : 'https://www.nubedeventas.com/'
    },
    
]

const Config = {
    production: true,
    BasePath : BasePath,
    //TestToken: testToken,
    SinImagen: SinImagen,
    SinAvatarUsuario: SinAvatarUsuario,
    NoticiasTest: noticiasTest,
    UploadBase64Path : UploadBase64Path,
    BaseUpload: BaseUpload,
}
export default Config;